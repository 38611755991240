<template>
  <div>
    <section class="bg-gradient-bottom bg-xl-gradient text-black">
      <div class="container jumbotron position-relative mb-0">
        <img
          class="d-xl-none media-mobile"
          :src="require('@/assets/image/company/media-mobile.png')"
          alt="media-mobile"
        >
        <img
          class="d-none d-xl-block newspaper"
          :src="require('@/assets/image/company/media-newspaper.png')"
          alt="girl"
        >
        <div class="media-title">
          <h2 class="mb-1 font-small-24 font-xl-36 font-weight-bold">
            媒體報導
          </h2>
          <p
            class="mb-3 font-small-60 font-xl-72 text-letter-xl-2 font-weight-bold position-relative border-gold"
          >
            PRESS
          </p>
          <p class="mb-1 pt-xl-5 font-small-18 font-xl-24">
            最新消息以及相關資料
          </p>
        </div>
        <img
          class="media-man d-xl-none"
          :src="require('@/assets/image/company/media-man-mobile.png')"
          alt="media-man"
        >
        <img
          class="media-man d-none d-xl-block"
          :src="require('@/assets/image/company/media-man.png')"
          alt="media-man"
        >
      </div>
    </section>
    <section class="container media-group">
      <div class="col-xl-12">
        <MediaCard />
      </div>
    </section>
  </div>
</template>

<script>
import MediaCard from '@/components/company/MediaCard'
export default {
  name: 'Media',
  components: {
    MediaCard
  }
}
</script>

<style lang="scss" scoped>
.jumbotron {
  height: 736px;

  .media-mobile {
    position: absolute;
    top: 15%;
    left: 10%;
    width: 80%;
  }

  .media-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .media-man {
    position: absolute;
    left: 50%;
    bottom: -5%;
    transform: translate(-50%, 0);
    width: 80%;
  }

  .border-gold::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0px;
    width: 50%;
    transform: translateX(-50%);
    border-bottom: 2px solid #ffcb57;
  }
}

.media-group {
  padding: 5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .jumbotron {
    .border-gold::after {
      width: 25%;
    }

    .media-mobile {
      top: 15%;
      left: 50%;
      width: 60%;
      transform: translate(-50%, 0);
    }

    .media-man {
      left: 0%;
      bottom: -10%;
      width: 50%;
      transform: translate(0%, 0);
    }
  }
}

@media (min-width: 1200px) {
  .jumbotron {
    height: 667px;

    .border-gold::after {
      border-bottom: 3px solid #ffcb57;
      width: 20%;
    }

    .media-title {
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }

    .newspaper {
      position: absolute;
      right: 12%;
      bottom: -10%;
      width: 30%;
      transform: translate(-12%, 0%);
    }

    .media-man {
      left: 8%;
      width: 30%;
    }
  }
}

@media (min-width: 768px) {
  .media-group {
    margin-top: 5rem;
  }
}
</style>
