<template>
  <div class="d-flex flex-wrap py-3 aligh-items-streach border-bottom">
    <div class="col-xl-4 order-lg-1">
      <div class="media-container position-relative">
        <img
          src
          alt="..."
        >
      </div>
    </div>
    <div class="col-xl-8 text-left pt-3 media-body">
      <div class="d-flex align-items-stretch">
        <img
          :src="require('@/assets/image/news/time.svg')"
          alt="time"
        >
        <p class="mb-0 align-self-center text-time">
          05 May 2020
        </p>
        <img
          class="ml-3"
          :src="require('@/assets/image/company/pen.svg')"
          alt="pen"
        >
        <p class="mb-0 align-self-center ml-1 text-time">
          xxxxxx
        </p>
      </div>
      <div class="position-relative mt-3">
        <h5 class="mt-0">
          使用FASNEY
        </h5>
        <h5 class="mt-0">
          終於沒信用卡也可分期付款！
        </h5>
        <div class="more-news">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-chevron-right"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>
      </div>
      <div
        class="media-text mt-3 mt-xl-5"
      >
        近期全球疫情延燒，乖乖宅在家才是最潮的自保行為。隨著民眾生活型態大轉彎，越來越多人傾向足不出戶、僅透過線上消費滿足生活所需。然而，過去許多線上支付都需仰賴信用卡才能辦理分期，對於沒有信用卡的學生、家庭主婦或SOHO小資族而言相當不方便......
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.media-container {
  width: 100%;
  padding-top: 70%;
  background-color: #d5d5d5;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

.media-body {
  color: #1d5198;

  .more-news {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border-radius: 50%;

    .bi-chevron-right {
      display: inline-block;
      margin: 0 6px;
      background-color: transparent;
      color: #000;
    }
  }
  .more-news:hover {
    background-color: #1e88e5;

    .bi-chevron-right {
      color: #fff;
    }
  }

  .media-text {
    color: #000;
    line-height: 2;
    font-weight: 300;
  }
}

@media (min-width: 1200px) {

}
</style>
